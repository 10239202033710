<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
          <c-card title="LBLBASEINFO" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <!-- <c-btn
                  v-if="editable && popupParam.envWaterDailyResultId"
                  label="일지출력"
                  color="amber-7"
                  @btnClicked="print" 
                  icon="print" /> -->
                <c-btn
                  v-if="editable && popupParam.envWaterDailyResultId"
                  label="전체화면 보기"
                  @btnClicked="fullScreenReport" 
                  icon="receipt_long" />
                <!-- <c-btn
                  v-if="editable && updateMode"
                  label="LBLREMOVE"
                  icon="remove"
                  @btnClicked="deleteData" /> -->
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveInfo"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-datepicker
                  :required="true"
                  :editable="false"
                  label="LBLREGDATE"
                  name="dailyResultDt"
                  v-model="data.dailyResultDt"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-field
                  :required="true"
                  :editable="false"
                  :data="data"
                  deptValue="deptCd"
                  type="dept_user"
                  label="작성부서 & 작성자"
                  name="userId"
                  v-model="data.userId">
                </c-field>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-plant :required="true" :editable="false" type="edit" name="plantCd" v-model="data.plantCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-select
                  :editable="editable"
                  codeGroupCd="WEATHER_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="weatherCd"
                  label="날씨"
                  v-model="data.weatherCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  label="온도"
                  suffix="℃"
                  name="temperature"
                  v-model="data.temperature">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-table
            ref="grid"
            title="근무자 목록"
            tableId="grid"
            :columns="grid.columns"
            :data="data.dailyUsers"
            :usePaging="false"
            :hideBottom="true"
            :columnSetting="false"
            :isFullScreen="false"
            :filtering="false"
            :isExcelDown="false"
            selection="multiple"
            rowKey="envWaterDailyResultUserId"
            gridHeight="190px"
            :editable="editable"
          >
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="" icon="add" @btnClicked="addUser" />
                <c-btn v-if="editable" label="" icon="remove" @btnClicked="removeUser" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 tabvertical" v-if="popupParam.envWaterDailyResultId">
          <c-tab
            type="vertical"
            :tabItems.sync="tabItems"
            :inlineLabel="true"
            v-model="tab"
            :dense="true"
            align="left"
            :height="tabHeight"
            :splitter="splitter"
            tabClassVertical="leftTabAlign"
          >
            <template v-slot:default="tab">
              <component
                :is="tab.component"
                :param.sync="popupParam"
              />
            </template>
          </c-tab>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'daily-report-detail',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          envWaterDailyResultId: '',
          isFullScreen: false,
          plantCd: null,
          dailyResultDt: '',
          approvalStatusCd: null,
        }
      },
    },
    contentHeight: null,
  },
  data() {
    return {
      splitter: { data: 280 },
      tab: 'tab1',
      tabItems: [],
      data: {
        plantCd: null,  // 사업장코드
        envWaterDailyResultId: '',  // 수질일지 일련번호
        dailyResultDt: '',  // 작성일
        deptCd: '',  // 작성부서
        userId: '',  // 작성자
        weatherCd: null,  // 날씨
        temperature: '',  // 온도
        approvalStatusCd: '',
        dailyUsers: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: false,
        width: '80%',
        top: '',
        param: {},
        closeCallback: null,
      },
      saveUrl: transactionConfig.env.water.daily.result.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      deleteUserUrl: '',
      editable: true,
      isSave: false,
      updateMode: false,
      detailUrl: '',
      isApproval: false,
      grid: {
        columns: [
          {
            name: 'userName',
            field: 'userName',
            label: '근무자',
            align: 'center',
            style: 'width:40%',
            sortable: false,
          },
          {
            name: 'startTime',
            field: 'startTime',
            label: '근무시작시간',
            align: 'center',
            type: 'datetime',
            minuteStep: 10,
            style: 'width:30%',
            sortable: false,
          },
          {
            name: 'endTime',
            field: 'endTime',
            label: '근무종료시간',
            align: 'center',
            type: 'datetime',
            minuteStep: 10,
            style: 'width:30%',
            sortable: false,
          },
        ],
      },
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 220);
    },
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.env.water.daily.result.get.url;
      this.saveUrl = transactionConfig.env.water.daily.result.insert.url;
      this.updateUrl = transactionConfig.env.water.daily.result.update.url;
      this.deleteUrl = transactionConfig.env.water.daily.result.delete.url;
      this.deleteUserUrl = transactionConfig.env.water.daily.user.delete.url;
      this.getDetail();
    },
    addUser() {
      this.popupOptions.title = "근무자 검색"; // 근무자 검색
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '60%';
      this.popupOptions.isFull = false;
      this.popupOptions.closeCallback = this.closeUserPopup;

    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          this.data.dailyUsers.push({
            envWaterDailyResultId: uid(),
            envWaterDailyResultUserId: uid(),
            userId: _item.userId,
            userName: _item.userName,
            chgUserId: this.$store.getters.user.userId,
            regUserId: this.$store.getters.user.userId,
            editFlag: 'C'
          })
        })
      }
    },
    removeUser() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUserUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.data.dailyUsers = this.$_.reject(this.data.dailyUsers, item);
              })
              this.$refs['grid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveDataAppr() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '결재요청 하기 전 입력된 값을 저장합니다.\n\r 진행하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.data.regUserId = this.$store.getters.user.userId;
          this.data.chgUserId = this.$store.getters.user.userId;
          let promises = [
            {
              func: this.approvalValue
            },
          ];
          this.$comm.orderedPromise(promises);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    // [S] 결재관련 
    approvalCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    approvalValue() {
      return new Promise(resolve => {
        this.isApproval = !this.isApproval
        resolve(true);
      });
    },
    getDetail() {
      if (this.popupParam.envWaterDailyResultId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {envWaterDailyResultId: this.popupParam.envWaterDailyResultId}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.updateMode = true;
          this.popupParam.plantCd = this.data.plantCd;
          this.popupParam.dailyResultDt = this.data.dailyResultDt;
          this.popupParam.approvalStatusCd = this.data.approvalStatusCd;
          this.popupParam.approvalTarget = true;
          if (this.tabItems.length == 0) {
            this.tabItems = [
              { name: 'tab1', label: '1. 폐수배출시설 가동(조업)시간대', component: () => import(`${'@/pages/env/water/dailyReport01.vue'}`), key: uid() },
              { name: 'tab2', label: '2. 수질오염방지시설 가동시간대', component: () => import(`${'@/pages/env/water/dailyReport02.vue'}`), key: uid() },
              { name: 'tab3', label: '3. 용수 공급원별 사용량과 폐수배출량', component: () => import(`${'@/pages/env/water/dailyReport03.vue'}`), key: uid() },
              { name: 'tab4', label: '4. 슬러지의 발생량 및 처리량', component: () => import(`${'@/pages/env/water/dailyReport04.vue'}`), key: uid() },
              { name: 'tab5', label: '5. 원료 또는 첨가제 등의 사용량', component: () => import(`${'@/pages/env/water/dailyReport05.vue'}`), key: uid() },
              { name: 'tab6', label: '6. 전력사용량', component: () => import(`${'@/pages/env/water/dailyReport06.vue'}`), key: uid() },
              { name: 'tab7', label: '7. 약품사용량', component: () => import(`${'@/pages/env/water/dailyReport07.vue'}`), key: uid() },
              { name: 'tab8', label: '8. 폭기조 운전상태', component: () => import(`${'@/pages/env/water/dailyReport08.vue'}`), key: uid() },
              { name: 'tab9', label: '9. 수질오염방지시설 고장 유무', component: () => import(`${'@/pages/env/water/dailyReport09.vue'}`), key: uid() },
              { name: 'tab10', label: '10. 수질오염물질 측정내용', component: () => import(`${'@/pages/env/water/dailyReport10.vue'}`), key: uid() },
              { name: 'tab11', label: '11. 측정항목별 점검내용', component: () => import(`${'@/pages/env/water/dailyReport11.vue'}`), key: uid() },
              { name: 'tab12', label: '12. 지도ㆍ점검을 받은 사항', component: () => import(`${'@/pages/env/water/dailyReport12.vue'}`), key: uid() },
            ]
          }
        },);
      } else {
        this.data.dailyResultDt = this.$comm.getToday();
        this.data.userId = this.$store.getters.user.userId;
        this.data.deptCd = this.$store.getters.user.deptCd;
      }
    },
    saveInfo() {
      if (this.popupParam.envWaterDailyResultId) {
        this.saveUrl = transactionConfig.env.water.daily.result.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.env.water.daily.result.insert.url;
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.popupParam.envWaterDailyResultId = result.data.envWaterDailyResultId;
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '일지전체 내용이 삭제됩니다.\r\n삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.envWaterDailyResultId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    fullScreenReport() {
      this.popupOptions.target = () => import(`${"@/pages/env/water/dailyReportFullScreen.vue"}`);
      this.popupOptions.title = '수질일지 전체';
      this.popupOptions.param = {
        envWaterDailyResultId: this.popupParam.envWaterDailyResultId,
        plantCd: this.popupParam.plantCd,
        isFullScreen: true,
        dailyResultDt: this.popupParam.dailyResultDt,
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    print() {
      window.open('/수질일지_샘플.xlsx');
    }
  }
};
</script>